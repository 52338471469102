import React from 'react';

import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './gallery.scss';

const Gallery = (props) => {

    return (
        <Modal 
            size="lg"
            show={props.galleryVisible}
            onHide={props.onCloseGallery}
            scrollable={false}>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg" style={{color: "#2f6e37" }}>Gallery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="gallery-body-container">
                    <Carousel>
                    { 
                        props.galleryImageLinks.map((url, idx) => {
                            return (
                                <Carousel.Item key={idx} interval={4000}>
                                    <img className="d-block w-100 image" src={url} alt="Construction" />
                                </Carousel.Item>
                            );
                        })
                    }
                    </Carousel>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onCloseGallery}>Close</Button>
            </Modal.Footer>
            </Modal>
    );
}

export default Gallery;