import React from 'react';

import Carousel from 'react-bootstrap/Carousel';

import './showcase.scss';

const Showcase = (props) => {
    const intervalSpan = 4000;
    
    return (
        <div className="main showcase">
                <div className="showcase__body">
                    <Carousel>
                    { 
                        props.carouselImageLinks.map((url, idx) => {
                            return (
                                <Carousel.Item key={idx} interval={intervalSpan}>
                                    <img className="d-block w-100" src={url} alt="Construction" height="300" />
                                </Carousel.Item>
                            );
                        })
                    }
                    </Carousel>
                </div>
                <div className="showcase__controls">
                    <button className="launch__gallery" onClick={props.onLaunchGallery} type="button">View Full Gallery</button>
                </div>
            </div>
    );
}

export default Showcase;