import React from 'react';

//Components
import Layout from './components/Layout/Layout';
import Header from './components/Header/Header';
import Contact from './components/Contact/Contact';
import Testimonials from './components/Testimonials/Testimonials';
import ServicesOffered from './components/ServicesOffered/ServicesOffered';
import Showcase from './components/Showcase/Showcase';
import Gallery from './components/Gallery/Gallery';

//Models
import Testimonial from './models/Testimonial';

//Styling
import './styling/common.scss';
import './styling/small.scss';
import './styling/medium.scss';
import './styling/large.scss';

function App() {

  const [siteDetails, ] = React.useState({
    name: "Diggers Projects",
    logoUrl: "./img/Logo.jpg"
  });

  const [testimonials, ] = React.useState([
      new Testimonial("Theresa", "Diggers Projects goes the extra mile to ensure jobs are done neatly, quickly and affordably. Couldn't be happier!"),
      new Testimonial("Gladwell", "With Clive you can always be assured of a job well done!"),
      new Testimonial("Martin", "I have used Diggers for a couple of maintenance jobs and have never been dissapointed.")
    ]);

  const [contactDetails, ] = React.useState({
    name: "Clive van Diggelen", 
    phone: "082 332 0886", 
    email: "clive@diggers.co.za"
  });

  const [carouselImageLinks, ] = React.useState([
    "./img/gallery/01.jpg",
    "./img/gallery/02.jpg", 
    "./img/gallery/11.jpg",
    "./img/gallery/13.jpg",
    "./img/gallery/71.jpg"
  ]);

  const [galleryImageLinks, ] = React.useState([
    "./img/gallery/01.jpg", 
    "./img/gallery/02.jpg", 
    "./img/gallery/03.jpg",
    "./img/gallery/04.jpg",
    "./img/gallery/05.jpg",
    "./img/gallery/06.jpg",
    "./img/gallery/07.jpg",
    "./img/gallery/08.jpg",
    "./img/gallery/11.jpg",
    "./img/gallery/12.jpg",
    "./img/gallery/13.jpg",
    "./img/gallery/21.jpg",
    "./img/gallery/31.jpg",
    "./img/gallery/32.jpg",
    "./img/gallery/33.jpg",
    "./img/gallery/41.jpg",
    "./img/gallery/42.jpg",
    "./img/gallery/43.jpg",
    "./img/gallery/44.jpg",
    "./img/gallery/51.jpg",
    "./img/gallery/61.jpg",
    "./img/gallery/71.jpg",
  ]);

  const [servicesData, ] = React.useState({
        construction: { 
          title: "Construction", 
          items: ["Building", "Renovations", "Foundations"] 
        },
        maintenance: { 
          title: "Maintenance", 
          items: ["General DIY", "Troubleshooting", "Repairs"] 
        },
        subcontracting: { 
          title: "Sub-Contracting", 
          items: ["Plumbing", "Electrical Work", "Equipment Hire"] 
        },
        other: { 
          title: "Other", 
          items: ["Trenching", "Landscaping", "More..."] 
        }
    });
  
  const [galleryVisible, setGalleryVisible] = React.useState(false);

  const handleLaunchGallery = () => {
    setGalleryVisible(() => true);
  }

  const handleCloseGallery = () => {
    setGalleryVisible(() => false);
  }

  return (
    <div className="container">
      <div className="site-layout">
        <Header siteDetails={siteDetails} />
        <Contact contactDetails={contactDetails} />
        <Testimonials testimonials={testimonials} />
        <Showcase carouselImageLinks={carouselImageLinks} onLaunchGallery={handleLaunchGallery} />
        <ServicesOffered servicesData={servicesData} />
        <Gallery 
                galleryVisible={galleryVisible} 
                onCloseGallery={handleCloseGallery} 
                galleryImageLinks={galleryImageLinks}
            />
      </div>
    </div>
  );
}

export default App;
