import React from 'react';

import './contact.scss';

const Contact = (props) => {
    return (
        <div className="sidebar contact">
            <div className="contact__header">
                <h5 className="brand-green">Contact Us</h5>
                <hr />
            </div>
            <div className="contact__body">
                <p>{props.contactDetails.name}</p>
                <p>{props.contactDetails.phone}</p>
                <p>{props.contactDetails.email}</p>
            </div>
        </div>
    );
}

export default Contact;