import React from 'react';

import Card from 'react-bootstrap/Card';

import CategoryItem from '../Main/components/CategoryItem';


import './servicesOffered.scss';

const ServicesOffered = (props) => {
    return (
        <div className="main servicesOffered">
            <div className="services__header">
                <h2 className="brand-green">Services Offered</h2>
                <hr />
            </div>
            <div className="services__container">

                <div className="service__card-construction">
                    <Card>
                        <Card.Body>
                            <Card.Title className="brand-green">{props.servicesData.construction.title}</Card.Title>
                                <ul>
                                    {props.servicesData.construction.items.map((t, idx) => <CategoryItem key={idx} text={t} />)}
                                </ul>
                        </Card.Body>
                    </Card>
                </div>

                <div className="service__card-maintenance">
                    <Card>
                        <Card.Body>
                            <Card.Title className="brand-green">{props.servicesData.maintenance.title}</Card.Title>
                            <ul>
                                {props.servicesData.maintenance.items.map((t, idx) => <CategoryItem key={idx} text={t} />)}
                            </ul>
                        </Card.Body>
                    </Card>
                </div>

                <div className="service__card-subcontracting">
                    <Card>
                        <Card.Body>
                            <Card.Title className="brand-green">{props.servicesData.subcontracting.title}</Card.Title>
                                <ul>
                                    {props.servicesData.subcontracting.items.map((t, idx) => <CategoryItem key={idx} text={t} />)}
                                </ul>
                        </Card.Body>
                    </Card>
                </div>

                <div className="service__card-other">
                    <Card>
                        <Card.Body>
                            <Card.Title className="brand-green">{props.servicesData.other.title}</Card.Title>
                                <ul>
                                    {props.servicesData.other.items.map((t, idx) => <CategoryItem key={idx} text={t} />)}
                                </ul>
                        </Card.Body>
                    </Card>
                </div>
            </div>
                </div>
    );
}

export default ServicesOffered;