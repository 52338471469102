import React from 'react';

import Testimonial from './components/Testimonial/Testimonial';

import './testimonials.scss';

const Testimonials = (props) => {
    return (
        <div className="sidebar testimonials">
            <div className="testimonials__header">
                <h5 className="brand-green">Testimonials</h5>
                <hr />
            </div>
            <div className="testimonials__body">
            {
                props.testimonials.map((t, idx) => {
                    return (
                        <Testimonial key={idx} name={t.name} text={t.text} />
                    );
                })
            }    
            </div>
        </div>
    );
}

export default Testimonials;
