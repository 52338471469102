import React from 'react';

import './header.scss';

const Header = (props) => {
    return (
        <div className="header">
            <div className="header__logo">
                <img src={props.siteDetails.logoUrl} alt="Logo" width="70" />
            </div>
            <div className="header_title">
                <h1 className="brand-green">{props.siteDetails.name}</h1>
            </div>
            
        </div>
    );
}

export default Header;

